import { FC, useContext, Fragment, useMemo } from "react";

import { SectionItemWrapper } from "./Item.styled";
import { setDefaultImage } from "common/Utils/defaults";
import { previewTypes } from "common/context/Preview/PreviewContext";
import { PreviewContext } from "common/context/Preview";
import { cropImage } from "@brightsites/flow-core/lib/utils/cropImage";

import BounceLoader from "react-spinners/BounceLoader";
import DateTime from "Components/DateTime";
import parse from "html-react-parser";
import GalleryIcon from 'common/icons/Gallery';
import { IconWrapper } from 'Components/Hero/Hero.styled';

type Props = {
  article: any;
  size: number;
  mpu?: boolean;
  role?: string;
  cropData?: {};
  capsuleLink?: string;
  capsuleName?: string;
  hasCustomCapsule?: boolean
  isHero?: boolean
  isMulti?: boolean
  showLead?: boolean
  className?: string;
};

const Item: FC<Props> = ({ article, size, mpu, role, className, cropData, capsuleLink, capsuleName, hasCustomCapsule, isHero, showLead, isMulti }) => {
  const { preview } = useContext<previewTypes>(PreviewContext);

  const isGallery = useMemo(() => {
    try {
      return (
        article?.hero?.some((item: any) => ["gallery"].includes(item?.type)) ||
        false
      );
    } catch (error) {
      console.error("Error determining video type:", error);
      return false; // default to false in case of an error
    }
  }, [article]);

  const showGalleryIcon = isGallery && size === 2 && isMulti;
  const description = article?.lead;
  const image =
    article?.hero && article?.hero[0].data.url
      ? article?.hero[0].data.url
      : setDefaultImage(article?.sections[0].name);

  const isDailyMotion =
    article?.hero && article?.hero[0].type === "dailymotion";

  const isYoutube = useMemo(() => {
    try {
      return (
        article?.hero?.some((item: any) =>
          ["video-youtube"].includes(item?.type)
        ) || false
      );
    } catch (error) {
      console.error("Error determining video type:", error);
      return false; // default to false in case of an error
    }
  }, [article]);
  const dailyMotionImage =
    article?.hero && article?.hero[1] && article?.hero[1].data.url
      ? article?.hero[1].data.url
      : article?.sections && setDefaultImage(article?.sections[0].name);

  const youtubeImage =
    article?.hero && article?.hero[1] && article?.hero[1].data.url
      ? article?.hero[1].data.url
      : article?.sections && setDefaultImage(article?.sections[0].name);

  const galleryPic = useMemo(() => {
    try {
      if (isGallery && article?.hero) {
        const galleryItem = article.hero.filter(
          (item: any) => item.type === "gallery"
        )[0];
        return galleryItem?.data?.gallery[0]?.data?.url || null;
      }
      return null;
    } catch (error) {
      console.error("Error retrieving gallery picture URL:", error);
      return null; // default to null in case of an error
    }
  }, [article, isGallery]);
  const titleShort = article?.titleShort;
  const isLive = useMemo(() => {
    try {
      return article?.liveblog?.data?.status === 1;
    } catch (error) {
      console.error("Error determining if article is live:", error);
      return false; // default to false in case of an error
    }
  }, [article]);

  const isSponsored = article?.extra && article.extra.isShowSponsoredCapsule;

  const isVideo = useMemo(() => {
    try {
      return (
        article?.hero?.some((item: any) =>
          ["video-youtube", "dailymotion"].includes(item?.type)
        ) || false
      );
    } catch (error) {
      console.error("Error determining video type:", error);
      return false; // default to false in case of an error
    }
  }, [article]);

  if (!article) {
    return null;
  }
  const renderItemImageUrl = (mobile: boolean) => {
    const imageToRender = isYoutube
      ? youtubeImage
      : isDailyMotion
        ? dailyMotionImage
        : image;

    if (isGallery) {
      if (size === 2 && isMulti) {
        return mobile
          ? `${galleryPic}?${cropImage(
            cropData
          )}width=118&height=64&crop=96:64`
          : `${galleryPic}?${cropImage(
            cropData
          )}width=480&height=280&crop=480:280`;
      }
      if (size === 3 && mpu === true) {
        return mobile
          ? `${galleryPic}?${cropImage(cropData)}width=96&height=64&crop=96:64`
          : `${galleryPic}?${cropImage(
            cropData
          )}width=209&height=145&crop=209:145`;
      }
      if (size === 3 && mpu === undefined) {
        return mobile
          ? `${galleryPic}?${cropImage(cropData)}width=96&height=64&crop=96:64`
          : `${galleryPic}?${cropImage(
            cropData
          )}width=320&height=160&crop=320:160`;
      }
      if (size === 6) {
        return mobile
          ? `${galleryPic}?${cropImage(cropData)}width=96&height=64&crop=96:64`
          : `${galleryPic}?${cropImage(
            cropData
          )}width=118&height=79&crop=118:79`;
      } else {
        return mobile
          ? `${galleryPic}?${cropImage(cropData)}width=96&height=64&crop=96:64`
          : `${galleryPic}?${cropImage(
            cropData
          )}width=320&height=160&crop=320:160`;
      }
    } else {

      if (isHero) {
        return mobile
          ? `${imageToRender}?${cropImage(
            cropData
          )}width=96&height=64&crop=96:64`
          : `${imageToRender}?${cropImage(
            cropData
          )}width=520&height=160&crop=520:160`;
      }
      if (size === 1) {
        return mobile
          ? `${imageToRender}?${cropImage(
            cropData
          )}width=118&height=64&crop=96:64`
          : `${imageToRender}?${cropImage(
            cropData
          )}width=240&height=212&crop=240:212`;
      }

      if (size === 2 && !isMulti) {
        return mobile
          ? `${imageToRender}?${cropImage(
            cropData
          )}width=118&height=64&crop=96:64`
          : `${imageToRender}?${cropImage(
            cropData
          )}width=211&height=188&crop=211:188`;
      }
      if (size === 2 && isMulti) {
        return mobile
          ? `${imageToRender}?${cropImage(
            cropData
          )}width=118&height=64&crop=96:64`
          : `${imageToRender}?${cropImage(
            cropData
          )}width=480&height=280&crop=480:280`;
      }

      if (size === 3) {
        return mobile
          ? `${imageToRender}?${cropImage(
            cropData
          )}width=125&height=80&crop=125:80`
          : `${imageToRender}?${cropImage(
            cropData
          )}width=310&height=153&crop=310:153`;
      }
      if (size === 4) {
        return mobile
          ? `${imageToRender}?${cropImage(
            cropData
          )}width=420&height=280&crop=3:2`
          : `${imageToRender}?${cropImage(
            cropData
          )}width=420&height=280&crop=3:2`;
      }

      if (size === 6 && mpu === true) {
        return mobile
          ? `${imageToRender}?${cropImage(
            cropData
          )}width=118&height=64&crop=96:64`
          : `${imageToRender}?${cropImage(
            cropData
          )}width=118&height=79&crop=118:79`;
      }
      if (size === 6 && mpu === undefined) {
        return mobile
          ? `${imageToRender}?${cropImage(
            cropData
          )}width=118&height=64&crop=96:64`
          : `${imageToRender}?${cropImage(
            cropData
          )}width=200&height=88&crop=119:88`;
      }
      if (size === 10) {
        return mobile
          ? `${imageToRender}?${cropImage(
            cropData
          )}width=96&height=64&crop=96:64`
          : `${imageToRender}?${cropImage(
            cropData
          )}width=240&height=145&crop=245:145`;
      } else {
        return mobile
          ? `${imageToRender}?${cropImage(
            cropData
          )}width=96&height=64&crop=96:64`
          : `${imageToRender}?${cropImage(
            cropData
          )}width=240&height=145&crop=245:145`;
      }
    }
  };

  return (
    <SectionItemWrapper mpu={mpu} size={size} className={className} isMulti={isMulti}>
      <div className="image_wrapper">
        <a href={preview ? "#" : article.path} style={{ position: "relative", display: "block", height: "100%" }}>
          {isLive && (
            <span className="live">
              {" "}
              <BounceLoader size="10px" color="#C80015" />{" "}
              <p style={{ marginLeft: 6 }}>live</p>
            </span>
          )}
          {showGalleryIcon && (
            <IconWrapper>
              {" "}
              <GalleryIcon />
            </IconWrapper>
          )}
          <img
            alt={titleShort ? titleShort : "image title"}
            src={renderItemImageUrl(false)}
            loading="lazy"
            srcSet={`
            ${renderItemImageUrl(true)}   540w,
            ${renderItemImageUrl(false)}   840w,

        `}
          />
        </a>
      </div>
      <div className="inner_wrapper">
        <div className="tab_wrapper">
          {hasCustomCapsule && capsuleLink || capsuleName ? (
            <>
              <div className="tab tab_sponsored">
                {capsuleName && <p>{capsuleName}</p>}
              </div>
              {capsuleLink && <a className="tab" href={capsuleLink} target="_blank" rel="noreferrer" >{capsuleLink}</a>}
            </>
          ) : isSponsored && !hasCustomCapsule ?  (
            <div className="tab tab_sponsored">
              <p>sponsored</p>
            </div>
          ) : (
            article?.hierarchy?.slice(-1).map((item: any, i: number) => {
              return (
                <Fragment key={i}>
                  <a className="tab" href={preview ? "#" : item.path}>
                    <div>
                      <p>{item.name}</p>
                    </div>
                  </a>
                </Fragment>
              );
            })
          )}
        </div>
        <div>
          <a style={{ display: "flex" }} href={preview ? "#" : article.path}>
            <div className="title">
              <p>{titleShort}</p>
              {showLead && (
                <a className='lead' href={preview ? "#" : article.path}>
                  {parse(description)}
                </a>
              )}
              {size === 10 && (
                <div className="date_wrapper">
                  {" "}
                  <DateTime
                    type="short"
                    time={new Date(article.firstPublished * 1000)}
                  />
                </div>
              )}
            </div>
          </a>
        </div>
      </div>
    </SectionItemWrapper>
  );
};

export default Item;
