import styled, { css } from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

type StyledProps = {
  size?: number;
  mpu?: boolean;
  isMulti?: boolean;
};

const breakingNews = "#c80015";

export const ItemX6 = css`
  display: grid;
  grid-template-columns: 119px 1fr;
  position: relative;
  font-size: 12px;
  @media (min-width: ${defaultBreakpoints.medium}) {
    max-height: 110px;
  }

  .inner_wrapper {
    padding: 8px;
    margin: auto 0;
    height: 100%;
  }
  .title {
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      -webkit-box-orient: vertical;
      @media (max-width: ${defaultBreakpoints.medium}) {
        -webkit-line-clamp: 2;
      }
    }
    :hover {
      text-decoration: underline;
    }
    @media (max-width: ${defaultBreakpoints.medium}) {
      max-width: 100%;
    }
  }
  .image_wrapper {
    overflow: hidden;

    @media (max-width: ${defaultBreakpoints.medium}) {
      display: none;
    }
  }
  img {
    height: 100%;
    object-fit: cover;
    width: 119px;
    border-radius: 16px 0 0 16px;
  }
  @media (max-width: ${defaultBreakpoints.medium}) {
    grid-template-columns: 1fr;
  }
  .live {
    position: absolute;
    top: 0;
    background-color: white;
    font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: left;
    text-transform: uppercase;
    display: flex;
    color: #c80015;
    align-items: center;
    padding: 7px 11px;
    p {
      margin: 0;
    }
    @media (max-width: ${defaultBreakpoints.medium}) {
      font-size: 11px;
      padding: 3px 5px;
    }
  }
`;

export const ItemX1 = css`
  display: grid;
  padding: 0;
  @media (max-width: ${defaultBreakpoints.medium}) {
    grid-template-columns: 112px 1fr;
  }

  .inner_wrapper {
    display: flex;
    flex-direction: column;
    padding: 8px;
  }

  .image_wrapper {
    overflow: hidden;
    img {
      border-radius: 16px 16px 0 0;
      @media (max-width: ${defaultBreakpoints.medium}) {
        height: 100%;
        width: 100%;
        border-radius: 16px 0 0 16px;
      }
    }
  }
  .lead {
    font-family: ${({ theme }) => theme.default.fontFamily.secondaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 8px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    max-width: 100%;
    min-width: 160px;

    @media (max-width: ${defaultBreakpoints.medium}) {
      font-size: 14px;
    }
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .tab {
    font-size: 11px;
    background-color: white;
    :hover {
      cursor: pointer;
    }
  }
  .tab:nth-child(even) {
    background-color: ${({ theme }) => theme.default.color.primary};
    color: white;
    :hover {
      background-color: ${({ theme }) => theme.default.color.primary};
      cursor: pointer;
      color: white;
    }
  }
  .live {
    position: absolute;
    top: 0;
    background-color: white;
    font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: left;
    text-transform: uppercase;
    display: flex;
    color: #c80015;
    align-items: center;
    padding: 7px 11px;
    p {
      margin: 0;
    }
    @media (max-width: ${defaultBreakpoints.medium}) {
      font-size: 11px;
      padding: 3px 5px;
    }
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    @media (max-width: ${defaultBreakpoints.medium}) {
      height: 64px;
      width: 96px;
    }
  }
`;
export const ItemX2 = css`
  display: grid;
  padding: 0;
  grid-template-rows: 1fr 1fr;
  @media (max-width: ${defaultBreakpoints.medium}) {
    grid-template-rows: 1fr;
  }
  .inner_wrapper {
    display: flex;
    flex-direction: column;
    padding: 8px;
  }

  .image_wrapper {
    overflow: hidden;
    img {
      border-radius: 16px 16px 0 0;
      @media (max-width: ${defaultBreakpoints.medium}) {
        height: 100%;
        width: 100%;
      }
    }
  }
  .lead {
    font-family: ${({ theme }) => theme.default.fontFamily.secondaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 8px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    @media (max-width: ${defaultBreakpoints.medium}) {
      display: grid;
    }
  }
  .title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    max-width: 80%;
    min-width: 160px;
    p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
    @media (max-width: ${defaultBreakpoints.medium}) {
      max-width: 95%;
    }
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .tab {
    font-size: 11px;
    background-color: white;
    :hover {
      cursor: pointer;
    }
  }
  .live {
    position: absolute;
    top: 0;
    background-color: white;
    font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: left;
    text-transform: uppercase;
    display: flex;
    color: #c80015;
    align-items: center;
    padding: 7px 11px;
    p {
      margin: 0;
    }
    @media (max-width: ${defaultBreakpoints.medium}) {
      font-size: 11px;
      padding: 3px 5px;
    }
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    border-radius: 16px 16px 0 0;
    @media (max-width: ${defaultBreakpoints.medium}) {
      height: 64px;
      width: 96px;
    }
  }
`;

export const ItemX2Multi = css`
  display: grid;
  padding: 0;
  .inner_wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px 8px;
  }
  .lead {
    font-family: ${({ theme }) => theme.default.fontFamily.secondaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 8px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .title {
    margin: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    width: 100%;
    line-height: 24px;
    p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
    @media (max-width: ${defaultBreakpoints.medium}) {
      max-width: 95%;
    }
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .tab {
    font-size: 11px;
    background-color: white;
    :hover {
      cursor: pointer;
    }
  }
  .live {
    position: absolute;
    top: 0;
    background-color: white;
    font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: left;
    text-transform: uppercase;
    display: flex;
    color: #c80015;
    align-items: center;
    padding: 7px 11px;
    p {
      margin: 0;
    }
    @media (max-width: ${defaultBreakpoints.medium}) {
      font-size: 11px;
      padding: 3px 5px;
    }
  }

  .image_wrapper {
    overflow: hidden;
    img {
      height: 280px;
      object-fit: cover;
      width: 100%;
      border-radius: 16px 16px 0 0;
      @media (max-width: ${defaultBreakpoints.medium}) {
        height: 200px;
      }
    }
  }
`;

export const ItemX3 = css`
  position: relative;
  display: grid;
  grid-template-columns: 125px 1fr;
  padding: 0;

  .inner_wrapper {
    display: flex;
    flex-direction: column;
    padding: 8px;
  }

  .image_wrapper {
    overflow: hidden;
    img {
      border-radius: 16px 0 0 16px;
      @media (max-width: ${defaultBreakpoints.medium}) {
        height: 100%;
        width: 100%;
      }
    }
  }
  .title {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    max-width: 80%;
    min-width: 160px;
    p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
      @media (max-width: ${defaultBreakpoints.medium}) {
        -webkit-line-clamp: 2;
      }
    }
    @media (max-width: ${defaultBreakpoints.medium}) {
      max-width: 95%;
    }
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .tab {
    font-size: 11px;
    background-color: white;
    :hover {
      cursor: pointer;
    }
  }
  .tab:nth-child(even) {
    background-color: ${({ theme }) => theme.default.color.primary};
    color: white;
    :hover {
      background-color: ${({ theme }) => theme.default.color.primary};
      cursor: pointer;
      color: white;
    }
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    @media (max-width: ${defaultBreakpoints.medium}) {
      height: 64px;
      width: 96px;
    }
  }
  .live {
    position: absolute;
    top: 0;
    background-color: white;
    font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: left;
    text-transform: uppercase;
    display: flex;
    color: #c80015;
    align-items: center;
    padding: 7px 11px;
    p {
      margin: 0;
    }
    @media (max-width: ${defaultBreakpoints.medium}) {
      font-size: 11px;
      padding: 3px 5px;
    }
  }
`;

export const ItemX4 = css`
  position: relative;
  display: grid;
  padding: 0;
  margin-bottom: 15px;
  @media (max-width: ${defaultBreakpoints.medium}) {
    padding: 12px 0;
    grid-template-columns: 1fr !important;
  }
  .inner_wrapper {
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    @media (max-width: ${defaultBreakpoints.medium}) {
      position: static;
    }
  }
  .title {
    font-size: 16px;
    max-width: 80%;
    min-width: 160px;
    p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
      @media (max-width: ${defaultBreakpoints.medium}) {
        -webkit-line-clamp: 2;
      }
    }
    @media (max-width: ${defaultBreakpoints.medium}) {
      max-width: 95%;
    }
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .tab {
    font-size: 11px;
    background-color: white;
    :hover {
      cursor: pointer;
    }
  }
  .tab:nth-child(even) {
    background-color: ${({ theme }) => theme.default.color.primary};
    color: white;
    :hover {
      background-color: ${({ theme }) => theme.default.color.primary};
      cursor: pointer;
      color: white;
    }
  }

  img {
    height: 280px;
    object-fit: cover;
    width: 100%;
    @media (max-width: ${defaultBreakpoints.medium}) {
      height: 200px;
    }
  }
  .live {
    position: absolute;
    top: 0;
    background-color: white;
    font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: left;
    text-transform: uppercase;
    display: flex;
    color: #c80015;
    align-items: center;
    padding: 7px 11px;
    p {
      margin: 0;
    }
    @media (max-width: ${defaultBreakpoints.medium}) {
      font-size: 11px;
      padding: 3px 5px;
    }
  }
`;

export const ItemX3PlusMpu = css`
  @media (max-width: ${defaultBreakpoints.medium}) {
    grid-template-columns: 112px 1fr;
  }
  .inner_wrapper {
    margin-top: 0;
    @media (max-width: ${defaultBreakpoints.medium}) {
      position: static;
    }
  }

  .title {
    font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 18px;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    @media (max-width: ${defaultBreakpoints.medium}) {
      font-size: 14px;
      line-height: 16px;
    }
    p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
      @media (max-width: ${defaultBreakpoints.medium}) {
        -webkit-line-clamp: 2;
      }
    }
    @media (max-width: ${defaultBreakpoints.medium}) {
      max-width: 95%;
    }
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .inner_wrapper {
    padding: 8px;
  }

  .image_wrapper {
    overflow: hidden;

    img {
      height: 153px;
      object-fit: cover;
      width: 100%;
      border-radius: 16px 16px 0 0;
      @media (max-width: ${defaultBreakpoints.medium}) {
        height: 100%;
        border-radius: 16px 0 0 16px;
      }
    }
  }
  .tab:nth-child(even) {
    background-color: ${({ theme }) => theme.default.color.primary};
    color: white;
    :hover {
      cursor: pointer;
      color: white;
    }
  }

  .live {
    position: absolute;
    top: 0;
    background-color: white;
    font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: left;
    text-transform: uppercase;
    display: grid;
    color: #c80015;
    align-items: center;
    padding: 4px 5px;
    grid-template-columns: 5px 1fr;
    p {
      margin: 0;
    }
    @media (max-width: ${defaultBreakpoints.medium}) {
      font-size: 11px;
    }
  }
  @media (max-width: ${defaultBreakpoints.medium}) {
    display: grid;
    font-size: 11px;
  }
`;

export const ItemSearch = css`
  display: flex;
  flex-direction: row-reverse;
  font-size: 12px;
  margin-bottom: 12px;
  justify-content: space-between;
  .inner_wrapper {
    padding: 16px;
  }
  .title {
    p {
      font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
      font-size: 18px;
      font-weight: 600;
      line-height: 30px;
      text-align: left;
      margin-bottom: 10px;
    }
  }
  img {
    height: 100%;
    width: 240px;
    min-width: 240px;
    object-fit: cover;
    border-radius: 0 16px 16px 0;
    @media (max-width: ${defaultBreakpoints.medium}) {
      display: none;
    }
  }

  .tab_wrapper {
    @media (max-width: ${defaultBreakpoints.medium}) {
      padding: 4px 0;
      max-width: 80%;
    }
  }

  .tab:nth-child(odd) {
    background-color: ${({ theme }) => theme.default.color.primary};
    color: white;
    :hover {
      color: white;
    }
  }
  .date_wrapper {
    font-family: ${({ theme }) => theme.default.fontFamily.secondaryFont};
    text-transform: uppercase;
    font-size: 11px;
  }
  .tab {
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: left;
    :hover {
      color: ${({ theme }) => theme.default.color.primary};
    }
  }
`;

export const SectionItemWrapper = styled.div<StyledProps>`
  cursor: pointer;
  position: relative;
  background: ${({ theme }) => theme.default.color.backgrounds.offWhite};
  border-radius: 16px;
  ${({ size }) => size === 10 && ItemSearch}
  ${({ size }) => size === 6 && ItemX6}
  ${({ size }) => size === 1 && ItemX1}
  ${({ size, isMulti }) => size === 2 && isMulti && ItemX2Multi}
  ${({ size, isMulti }) => size === 2 && !isMulti && ItemX2}
  ${({ size, mpu }) => size === 3 && !mpu && ItemX3}
  ${({ size }) => size === 4 && ItemX4}
  ${({ size, mpu }) => size === 3 && mpu && ItemX3PlusMpu}
  
  .tab_wrapper {
    display: flex;
  }
  .breaking_news {
    background-color: ${breakingNews};
    color: white;
    text-transform: uppercase;
    float: left;
    padding: 1px 8px;
    margin: 0;
    font-weight: 900;
    letter-spacing: 0.05em;
    bottom: 0;
    font-size: 11px;
    p {
      margin: 0;
    }
  }

  }
  .tab {
    font-family:${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 12px; /* 100% */
    text-transform: uppercase;
    color: ${({ theme }) => theme.default.color.backgrounds.white};
    background: ${({ theme }) => theme.default.color.primary};  
    padding: 4px;
    border-radius: 2px;  
    margin-bottom: 4px;  
    p {
      margin: 0;
    }
  }
  .tab_sponsored {
    background-color: #B11319;
  }

`;
