import styled, { css } from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

type StyledProps = {
  size?: number;
};

const breakingNews = "#c80015";

export const HeroPlus3 = css`
  display: grid;
  grid-template-columns: 2fr 1fr;
  background: ${({ theme }) => theme.default.color.backgrounds.offWhite};
  border-radius: 16px;
  @media (max-width: ${defaultBreakpoints.medium}) {
    grid-template-columns: 1fr;
    margin-bottom: 12px;  
  }
}  
  .inner_wrapper {
    background-color: ${({ theme }) => theme.default.color.offWhite};
    width: 100%;
    padding: 16px;  
  }
  .hero_image {
    width: 100%;
    height: 100%;  
    object-fit: cover;
    cursor: pointer;
    border-radius: 16px;
    @media (max-width: ${defaultBreakpoints.small}) {
      height: 350px;
    }
  }
  .h1 {
    font-style: normal;
    line-height: 40px;
    text-align: left;
    font-weight: 800;
    font-size: 32px;
    margin: 0px;
    max-width: 500px;
  }
  .title_wrapper {
    margin: 0;
    min-width: 250px;
    font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px; 
    width: 100%;
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

export const HeroPlus1 = css`
    display: grid;
    grid-template-columns: 2fr 1fr;
    background: ${({ theme }) => theme.default.color.backgrounds.offWhite};
    border-radius: 16px;
    @media (max-width: ${defaultBreakpoints.medium}) {
      grid-template-columns: 1fr;
      padding-right: 0;
      margin-bottom: 12px;  
    }
  }
  .inner_wrapper {
    background-color: ${({ theme }) => theme.default.color.offWhite};
    width: 100%;
    padding: 16px;  
  }
  .hero_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    border-radius: 16px;
    max-height: 350px;  
    @media (max-width: ${defaultBreakpoints.medium}) {
      height: 320px;
      border-radius: 16px 16px 0 0;
    }
  }
  .h1 {
    font-style: normal;
    line-height: 40px;
    text-align: left;
    font-weight: 800;
    font-size: 32px;
    margin: 0px;
    max-width: 500px;
  }
  .title_wrapper {
    margin: 0;
    min-width: 250px;
    font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    width: 100%;
    :hover {
        cursor: pointer;
        text-decoration: underline;
    }
  }
`;

export const HeroPlus2 = css`
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding-right: 12px;
    background: ${({ theme }) => theme.default.color.backgrounds.offWhite};
    column-gap: 16px;
    border-radius: 16px;
    @media (max-width: ${defaultBreakpoints.medium}) {
      grid-template-columns: 1fr;
      padding-bottom: 20px;
      padding-right: 0;
      margin-bottom: 12px;  
    }
  }
  .inner_wrapper {
    background-color: ${({ theme }) => theme.default.color.offWhite};
    margin: auto 0;
    width: 100%;
    display: grid;
    row-gap: 8px;
  }
  .hero_image {
    width: 100%;
    height: 100%;
    min-height: 300px;  
    object-fit: cover;
    cursor: pointer;
    border-radius: 16px;
    @media (max-width: ${defaultBreakpoints.medium}) {
      height: 350px;
      width: 100%;
    }
  }
  .h1 {
    font-style: normal;
    line-height: 40px;
    text-align: left;
    font-weight: 800;
    font-size: 32px;
    margin: 0px;
    max-width: 500px;
  }
  .title_wrapper {
    margin: 0;
    min-width: 250px;
    font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    width: 100%;
    :hover {
        cursor: pointer;
        text-decoration: underline;
    }
  }
`;
export const HeroPlus6 = css`
  background: ${({ theme }) => theme.default.color.backgrounds.offWhite};
  border-radius: 16px;

  .inner_wrapper {
    padding: 8px;
  }
  .hero_image {
    width: 100%;
    height: 210px;
    object-fit: cover;
    border-radius: 16px;
    cursor: pointer;
    @media (max-width: ${defaultBreakpoints.medium}) {
      height: 350px;
    }
  }
  .h1 {
    font-style: normal;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    margin: 0px;
    max-width: 500px;
  }
  .hero_body {
    display: none;
  }
  .inner_wrapper {
    background-color: ${({ theme }) => theme.default.color.offWhite};
    margin: auto 0;
    width: 100%;
    display: grid;
    row-gap: 8px;
  }
  .title_wrapper {
    margin: 0;
    min-width: 250px;
    font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    width: 100%;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
    @media (max-width: ${defaultBreakpoints.medium}) {
      font-size: 24px;
    }
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

export const IconWrapper = styled.div`
  background-color: ${({ theme }) => theme.default.color.primary};
  border-radius: 100px;
  height: 52px;
  width: 52px;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 28px;
  left: 8px;
  svg {
    fill: white;
    margin: auto;
  }
`;
export const HeroWrapper = styled.div<StyledProps>`
  ${({ size }) => size === 1 && HeroPlus1}
  ${({ size }) => size === 2 && HeroPlus2}
  ${({ size }) => size === 3 && HeroPlus3}
  ${({ size }) => size === 6 && HeroPlus6}

  .tab_sponsored {
    background-color: #b11319 !important;
  }
  position: relative;
  .tab:nth-child(even) {
    background-color: ${({ theme }) => theme.default.color.primary};
    color: ${({ theme }) => theme.default.color.text.white};
    :hover {
      background-color: ${({ theme }) => theme.default.color.primary};
      cursor: pointer;
      color: ${({ theme }) => theme.default.color.text.white};
    }
  }
  .tab_wrapper {
    display: flex;
  }

  .tab {
    font-size: 12px;
    color: ${({ theme }) => theme.default.color.text.white};
    background-color: ${({ theme }) => theme.default.color.primary};
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
    font-style: normal;
    font-weight: 800;
    line-height: 12px;
    text-align: left;
    padding: 3px 8px;
    .p {
      margin: 0;
    }
    :hover {
      cursor: pointer;
    }
  }
  .hero_body {
    p {
      font-family: ${({ theme }) => theme.default.fontFamily.secondaryFont};
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      margin: 0;
      @media (max-width: ${defaultBreakpoints.medium}) {
        font-size: 13px;
      }
    }
    :hover {
      cursor: pointer;
    }
  }

  .inner_wrapper {
    bottom: 0;
    @media (max-width: ${defaultBreakpoints.medium}) {
      padding: 8px;
    }
  }
  .live {
    position: absolute;
    top: 0;
    background-color: white;
    font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: left;
    text-transform: uppercase;
    display: flex;
    color: #c80015;
    align-items: center;
    padding: 7px 11px;
    p {
      margin: 0;
    }
    @media (max-width: ${defaultBreakpoints.medium}) {
      font-size: 11px;
      padding: 3px 5px;
    }
  }
  .breaking_news {
    background-color: ${breakingNews};
    color: white;
    text-transform: uppercase;
    float: left;
    padding: 3px 8px;
    margin: 0;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 0.05em;
    bottom: 0;
    font-size: 11px;
    font-family: ${({ theme }) => theme.default.fontFamily.secondaryFont};
    p {
      margin: 0;
    }
  }
  .author {
    color: ${({ theme }) => theme.default.color.primary};
    font-family: ${({ theme }) => theme.default.fontFamily.secondaryFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    margin: 10px auto 2px 8px;
    @media (max-width: ${defaultBreakpoints.medium}) {
      display: none;
    }
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;
